const middleware = {}

middleware['auth-admin'] = require('../middleware/auth-admin.js')
middleware['auth-admin'] = middleware['auth-admin'].default || middleware['auth-admin']

middleware['auth-owner'] = require('../middleware/auth-owner.js')
middleware['auth-owner'] = middleware['auth-owner'].default || middleware['auth-owner']

middleware['auth-qontak-user'] = require('../middleware/auth-qontak-user.js')
middleware['auth-qontak-user'] = middleware['auth-qontak-user'].default || middleware['auth-qontak-user']

middleware['login'] = require('../middleware/login.js')
middleware['login'] = middleware['login'].default || middleware['login']

middleware['redirection'] = require('../middleware/redirection.js')
middleware['redirection'] = middleware['redirection'].default || middleware['redirection']

middleware['settings'] = require('../middleware/settings.js')
middleware['settings'] = middleware['settings'].default || middleware['settings']

middleware['sso-callback'] = require('../middleware/sso-callback.js')
middleware['sso-callback'] = middleware['sso-callback'].default || middleware['sso-callback']

middleware['version-switcher'] = require('../middleware/version-switcher.js')
middleware['version-switcher'] = middleware['version-switcher'].default || middleware['version-switcher']

export default middleware
